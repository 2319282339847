import { captureException } from "@sentry/nextjs";
import { db } from "config/firebaseClient";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect } from "react";

export async function getAllStatistics() {
  const statisticsRef = collection(db, "statistics");
  const q = query(statisticsRef, orderBy("createdAt"));
  const querySnapshot = await getDocs(q);
  const statisticsDocs = [];
  querySnapshot.forEach((statisticsSnapshot) => {
    statisticsDocs.push(statisticsSnapshot.data());
  });
  return statisticsDocs;
}

export function useAllStatisticsSubscription(handleUpdate) {
  try {
    useEffect(() => {
      let didCancel = false;

      const q = query(
        collection(db, "statistics"),
        orderBy("createdAt", "desc"),
        limit(365)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const statistics = [];

          querySnapshot.forEach((queryDoc) => {
            statistics.push({ ...queryDoc.data(), id: queryDoc.id });
          });

          if (!didCancel) {
            handleUpdate(statistics);
          }
        },
        captureException
      );

      return () => {
        didCancel = true;
        unsubscribe();
      };
    }, [handleUpdate]);
  } catch (error) {
    captureException(error);
  }
}
